@use '@angular/material' as mat;
// @import '~saturn-datepicker/theming';
@import 'app-theme';

// Plus imports for other components in your app.
@import 'color';

// Include the base styles for Angular Material core. We include this here so that you only
// have to load a single css file for Angular Material in your app.
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
// Defined in color.scss

// Create the theme object (a Sass map containing all of the palettes).
$nk3-theme: mat.define-light-theme($nk3-primary, $nk3-accent, $nk3-error);
$nk3-theme-light: mat.define-light-theme($nk3-primary-light, $nk3-accent, $nk3-error);

$nk3-admin-theme: mat.define-light-theme($nk3-admin-primary, $nk3-admin-accent, $nk3-admin-error);
$nk3-admin-theme-light: mat.define-light-theme($nk3-admin-primary-light, $nk3-admin-accent, $nk3-admin-error);

$nk3-master-theme: mat.define-light-theme($nk3-master-primary, $nk3-master-accent, $nk3-master-error);
$nk3-master-theme-light: mat.define-light-theme($nk3-master-primary-light, $nk3-master-accent, $nk3-master-error);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($nk3-theme);
// @include sat-datepicker-theme($nk3-theme);
@include app-theme($nk3-theme);

.admin-switch {
    @include mat.all-component-themes($nk3-theme-light);
}

app-admin {
    @include mat.all-component-themes($nk3-admin-theme);
    // @include sat-datepicker-theme($nk3-admin-theme);
    @include app-theme($nk3-admin-theme);

    .admin-switch {
        @include mat.all-component-themes($nk3-admin-theme-light);
    }

    .disableIt {
        color: rgba(0, 0, 0, 0.26);
        cursor: not-allowed;
    }

    .mat-checkbox-checked.mat-accent .mat-checkbox-background,
    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
        background-color: #3e5365 !important;
    }
    .mat-header-cell {
        color: rgba(0, 0, 0, 0.87) !important;
    }
}

app-master {
    @include mat.all-component-themes($nk3-master-theme);
    // @include sat-datepicker-theme($nk3-master-theme);
    @include app-theme($nk3-master-theme);

    .admin-switch {
        @include mat.all-component-themes($nk3-master-theme-light);
    }

    app-table-toolbar,
    app-table-toolbar mat-toolbar,
    .toolbar .mat-toolbar.mat-accent {
        background-color: #fff !important;
    }
    // .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
    //     background-color: #abd5da !important;
    // }
}

@use '@angular/material' as mat;
@import '../../../material/src/assets/color';

:host {
    min-height: 100vh;

    .mobile-side-container {
        min-height: 100%;
        position: relative;

        p.version {
            position: absolute;
            bottom: 0;
            width: 100%;
            text-align: right;
            color: #fff;
            padding-right: 10px;
            font-size: 0.5em;
        }
    }

    .mobile-side {
        min-height: 100%;
        width: 70%;
    }

    .side-nav-data {
        width: 20%;
        background-color: #fff !important;
        border-left: 4px solid #abd5da;
        min-width: 333px;

        @media (max-width: 959px) {
            width: 50%;
            min-width: unset;
        }

        @media (max-width: 599px) {
            width: 70%;
        }
    }

    ::ng-deep .mat-drawer-content {
        min-height: 100vh;

        @media (max-width: 599px) {
            overflow: initial;
        }
    }

    ::ng-deep .mobile-side-container .mat-drawer-content {
        padding-top: 108px;

        * .mat-drawer-content {
            padding-top: 0;
        }
    }
    ::ng-deep .mobile-side-container.isAu .mat-drawer-content {
        padding-top: 70px;

        * .mat-drawer-content {
            padding-top: 0;
        }
    }

    ::ng-deep .mobile-menu-wrapper {
        mat-expansion-panel-header {
            background: inherit !important;
        }
    }
}

@mixin app-menu-theme($theme) {
    $primary: map-get($theme, primary);

    .mat-drawer.mat-drawer-push.mobile-side,
    .mat-drawer.mat-drawer-over.mobile-side {
        background-color: mat.get-color-from-palette($primary);
    }
}

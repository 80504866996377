@use '@angular/material' as mat;
@import 'color';

html,
body {
    margin: 0;
    height: 100%;
    display: block;

    .player-sheet-purchase {
        opacity: 0.9 !important;
        background-color: #000;
        width: 100%;
    }
}

* {
    box-sizing: border-box;
}

app-klickdata {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;

    .mat-card .dashboard-card-title {
        padding-right: 2em;
    }
}
.relative {
    position: relative;
}
.p-0 {
    padding: 0 !important;
}
.p-1 {
    padding: 1em !important;
}
.m-1 {
    margin: 1em !important;
}

.p-x-1 {
    padding: 1em 0 !important;
}

.p-y-1 {
    padding: 0 1em !important;
}

.m-x-1 {
    margin: 1em 0 !important;
}

.m-y-1 {
    margin: 0 1em !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.m-0 {
    margin: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}
.mt-1 {
    margin-top: 1em !important;
}
.mt-2 {
    margin-top: 2em !important;
}
.w-10 {
    width: 10% !important;
}
.w-20 {
    width: 20% !important;
}
.w-25 {
    width: 25% !important;
}
.w-30 {
    width: 30% !important;
}
.w-40 {
    width: 40% !important;
}
.w-50 {
    width: 50% !important;
}
.w-60 {
    width: 60% !important;
}
.w-70 {
    width: 70% !important;
}
.w-80 {
    width: 80% !important;
}
.w-90 {
    width: 90% !important;
}
.w-100 {
    width: 100% !important;
}
.white-color {
    color: #fff !important;
}
.white-bg {
    background-color: #fff !important;
}
.white-border {
    border: 1px solid #fff !important;
}
.red-color {
    color: #e44a66 !important;
}
.red-bg {
    background-color: #e44a66 !important;
}
.red-border {
    border: 1px solid #e44a66 !important;
}
.orange-color {
    color: #ff9961 !important;
}
.orange-bg {
    background-color: #ff9961 !important;
}
.orange-border {
    border: 1px solid #ff9961 !important;
}
.ice-blue-color {
    color: #93cbd1 !important;
}
.ice-blue-bg {
    background-color: #93cbd1 !important;
}
.ice-blue-border {
    border: 1px solid #93cbd1 !important;
}
.dark-blue-color {
    color: #3e5365 !important;
}
.dark-blue-bg {
    background-color: #3e5365 !important;
}
.dark-blue-border {
    border: 1px solid #3e5365 !important;
}
.green-color {
    color: #bfd8d0 !important;
}
.green-bg {
    background-color: #bfd8d0 !important;
}
.green-border {
    border: 1px solid #bfd8d0 !important;
}
.light-grey-color {
    color: #f4f4f4 !important;
}
.light-grey-bg {
    background-color: #f4f4f4 !important;
}
.light-grey-border {
    border: 1px solid #f4f4f4 !important;
}
.grey-color {
    color: rgba(0, 0, 0, 0.6) !important;
}
.grey-bg {
    background-color: rgba(0, 0, 0, 0.6) !important;
}
.grey-border {
    border: 1px solid rgba(0, 0, 0, 0.6) !important;
}
.fontsize-12 {
    font-size: 12px !important;
}
.fontsize-14 {
    font-size: 14px !important;
}
.fontsize-16 {
    font-size: 16px !important;
}
.fontsize-18 {
    font-size: 18px !important;
}
.fontsize-20 {
    font-size: 20px !important;
}
.fontsize-24 {
    font-size: 24px !important;
}
.border-top-green-2 {
    border-top: 2px solid #bfd8d0 !important;
}
.border-top-green-1 {
    border-top: 1px solid #bfd8d0 !important;
}
.border-top-green-3 {
    border-top: 3px solid #bfd8d0 !important;
}
.border-bottom-green-2 {
    border-bottom: 2px solid #bfd8d0 !important;
}
.border-bottom-green-1 {
    border-bottom: 1px solid #bfd8d0 !important;
}
.border-bottom-green-3 {
    border-bottom: 3px solid #bfd8d0 !important;
}
.border-bottom-blue-3 {
    border-bottom: 3px solid #3e5365 !important;
}
.border-0 {
    border: none !important;
}
.pointer {
    cursor: pointer !important;
}
@media only screen and (max-width: 959px) {
    .mob-p-0 {
        padding: 0 !important;
    }
    .mob-p-1 {
        padding: 1em !important;
    }
    .mob-m-1 {
        margin: 1em !important;
    }

    .mob-p-x-1 {
        padding: 1em 0 !important;
    }

    .mob-p-y-1 {
        padding: 0 1em !important;
    }

    .mob-m-x-1 {
        margin: 1em 0 !important;
    }

    .mob-m-y-1 {
        margin: 0 1em !important;
    }

    .mob-pl-0 {
        padding-left: 0 !important;
    }

    .mob-pr-0 {
        padding-right: 0 !important;
    }

    .mob-pt-0 {
        padding-top: 0 !important;
    }

    .mob-pb-0 {
        padding-bottom: 0 !important;
    }

    .mob-m-0 {
        margin: 0 !important;
    }

    .mob-ml-0 {
        margin-left: 0 !important;
    }

    .mob-mr-0 {
        margin-right: 0 !important;
    }

    .mob-mt-0 {
        margin-top: 0 !important;
    }

    .mob-mb-0 {
        margin-bottom: 0 !important;
    }
    .mob-mt-1 {
        margin-top: 1em !important;
    }
    .mob-mt-2 {
        margin-top: 2em !important;
    }
    .mob-w-10 {
        width: 10% !important;
    }
    .mob-w-20 {
        width: 20% !important;
    }
    .mob-w-25 {
        width: 25% !important;
    }
    .mob-w-30 {
        width: 30% !important;
    }
    .mob-w-40 {
        width: 40% !important;
    }
    .mob-w-50 {
        width: 50% !important;
    }
    .mob-w-60 {
        width: 60% !important;
    }
    .mob-w-70 {
        width: 70% !important;
    }
    .mob-w-80 {
        width: 80% !important;
    }
    .mob-w-90 {
        width: 90% !important;
    }
    .mob-w-100 {
        width: 100% !important;
    }
}
app-guest,
app-home,
app-admin,
app-master {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;

    .search-icon-size {
        font-size: 20px !important;
    }

    .kd-green-top {
        padding: 0 !important;
        border-top: 5px solid #d9e8e3;
        border-radius: 0;
        box-shadow: none !important;
    }

    .green-top-border {
        border-top: 5px solid #d9e8e3;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }

    app-table-toolbar mat-accordion mat-expansion-panel {
        border-top-right-radius: 0 !important;
    }

    .tag-chip-item {
        background-color: #bfd8d0 !important;
        color: #3e5365 !important;
    }

    .cat-chip-item {
        background-color: #ff9961 !important;
        color: #fff !important;
    }

    app-table-filter .mat-standard-chip:focus::after,
    app-table-filter .mat-standard-chip:hover::after {
        opacity: 0;
    }

    ::ng-deep {
        .kd-green-top {
            .mat-expansion-panel-content,
            mat-table,
            mat-paginator {
                padding: 0 16px;
            }
        }

        mat-expansion-panel.kd-green-indicator {
            box-shadow: none !important;

            .mat-expansion-panel-header {
                position: relative;
                height: 24px !important;
            }

            .mat-expansion-indicator {
                background-color: #d9e7e3;
                position: absolute;
                right: 0px;
                top: -3px;
                padding: 0px 7px 4px 6px;
            }
        }
    }
    .scrolling-header {
        position: fixed !important;
        width: 100%;
        transform: translateY(0);
        visibility: visible;
        transition: 0.5s ease-in-out;
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
    }

    .scrolling-header.slide-down {
        transform: translateY(-100%);
        visibility: hidden;
    }

    .two-columns-layout {
        display: flex !important;
        gap: 2%;

        .first-child-layout {
            width: 72%;
            min-width: 72%;
            max-width: 72%;

            @media screen and (max-width: 959px) {
                width: 100%;
                min-width: 100%;
                max-width: 100%;
                padding: 8px;
            }
        }

        .second-child-layout {
            min-width: 25%;
            max-width: 25%;
            width: 25%;

            @media screen and (max-width: 959px) {
                width: 100%;
                min-width: 100%;
                max-width: 100%;
                padding: 8px;
            }
        }

        .border-green-top {
            border-top: 3px solid #abd5da;
            border-top-right-radius: 0;
            border-top-left-radius: 0;

            @media screen and (max-width: 959px) {
                border: none;
            }
        }
    }

    .dashboard-mobile-accordion {
        @media screen and (max-width: 959px) {
            mat-expansion-panel {
                border-radius: 0;
                margin: 0;
                border-bottom: 2px solid #c0d8cf !important;
                box-shadow: none !important;
                padding: 0 5px;
            }
            mat-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 21px;
            }
            mat-panel-title {
                display: flex;
                margin-right: 16px;
                align-items: center;
                gap: 5px;
                flex-grow: 1;
            }

            .mat-expansion-panel-header {
                height: 44px;
                padding: 0 5px !important;
            }

            p {
                padding: 0 10px;
                margin-bottom: 10px;
                margin-top: 0;
            }
        }
    }
    .mat-drawer-side {
        border-right: none;
    }
}
::ng-deep {
    @media only screen and (max-width: 959px) {
        .mat-paginator {
            .mat-paginator-container {
                min-height: 40px;
                padding: 0;
            }

            .mat-paginator-page-size {
                margin: 0;
            }

            .mat-paginator-page-size-select {
                margin: 0;
                width: 30px;
                font-size: 8px;
            }

            .mat-paginator-range-actions button {
                width: 30px;
                height: 30px;
                line-height: 30px;
            }
        }
    }
    mat-bottom-sheet-container {
        padding: 0 !important;
    }
}
mat-bottom-sheet-container {
    padding: 0 !important;
}

app-home-dashboard {
    .dashboard-view-more {
        position: absolute;
        right: 2em;
        top: 4px;
        width: 40px;
        height: 40px;
        z-index: 20;

        .mat-button-wrapper {
            padding: 6px 0;
        }
    }
}

.right-direction {
    direction: rtl;
}

div[dir='rtl'].parent-root {
    app-home-dashboard .dashboard-view-more {
        left: 2em;
        right: auto;
        transform: rotate(180deg);
    }

    .ml-1 {
        margin-left: inherit !important;
        margin-right: 1em;
    }

    .ml-2 {
        margin-left: inherit !important;
        margin-right: 2em;
    }

    .mr-1 {
        margin-right: inherit !important;
        margin-left: 1em;
    }

    .mr-2 {
        margin-right: inherit !important;
        margin-left: 2em;
    }

    .pr-1 {
        padding-right: inherit !important;
        padding-left: 1em;
    }

    .pl-1 {
        padding-left: inherit !important;
        padding-right: 1em;
    }

    app-search {
        margin-right: inherit !important;
        margin-left: 0.5em;
    }

    .mat-chip:not(.mat-basic-chip) {
        margin-top: 2px;
        margin-right: inherit !important;
        margin-left: 8px;
        margin-bottom: 2px;
    }

    .mat-chip:not(.mat-basic-chip) + .mat-chip:not(.mat-basic-chip) {
        margin-right: 0;
        margin-top: 2px;
        margin-left: 8px;
        margin-bottom: 2px;
    }

    .note {
        position: relative;
        background-color: whitesmoke;
        padding: 1em 2em;

        &:before {
            border-radius: 0 50% 50% 0;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            left: auto;
            right: -4px;
            line-height: 20px;
            position: absolute;
            width: 20px;
            height: 20px;
            text-align: center;
            top: 14px;
            top: calc(50% - 10px);
            transform: rotate(180deg);
        }
    }

    .mat-card .dashboard-card-title {
        padding-left: inherit !important;
        padding-right: 2em;
    }

    mat-icon.header-icon {
        margin-left: 10px;
        margin-right: inherit !important;
    }

    mat-icon.kd-icon-player {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }

    // mat-icon.kd-icon-player.header-icon {
    //     margin-right: 10px;
    //     margin-left: inherit !important;
    // }
    app-table-toolbar.toolbar app-table-filter mat-toolbar .mat-button .mat-button-wrapper .mat-icon {
        margin-left: 8px;
        margin-right: inherit !important;
    }

    app-template-settings .form-note {
        border-right: 4px solid #abd5da;
        border-left: 0 !important;
    }

    app-template-settings .form-note:before {
        right: -12px;
    }

    .ml-8p {
        margin-left: 8px;
        margin-right: inherit !important;
    }

    @media only screen and (max-width: 959px) {
        // margin left - 7px - rtl - mobile
        .ml-7p-r-m {
            margin-left: 7px !important;
            margin-right: 0 !important;
        }

        .pr-10p-r-m {
            padding-left: inherit !important;
            padding-right: 10px;
        }
    }
    .mat-button .mat-button-wrapper .mat-icon {
        margin-right: 0px;
        margin-left: 8px;
    }
    .mat-drawer-side {
        border-left: none;
    }
    mat-expansion-panel-header .mat-expansion-indicator {
        left: 0 !important;
        right: unset !important;
    }
    mat-table {
        mat-row:hover .hover {
            right: 103px;
            left: unset;
        }
    }
}

.nk3-toolbar-btn {
    min-width: 40px;
}

.flex,
.flex.mat-card-title,
.flex.mat-card-actions {
    display: flex;
}

.flex-grow {
    flex-grow: 1;
}

.flex-half {
    flex: 1 1 50%;
}

// Use to add space between two flex objects.
.flex-space {
    flex: 1 1 auto;
}

.flex-center {
    align-items: center;
}

.space {
    flex: 0 1 1em;
    width: 1em;
}

.mt-1 {
    margin-top: 1em;
}

.pt-1 {
    padding-top: 1em;
}

.mt-2 {
    margin-top: 2em;
}

.pt-2 {
    padding-top: 2em;
}

.mb-1 {
    margin-bottom: 1em;
}

.mb-2 {
    margin-bottom: 2em;
}

.ml-1 {
    margin-left: 1em;
}

.ml-2 {
    margin-left: 2em;
}

.mr-1 {
    margin-right: 1em;
}

.mr-2 {
    margin-right: 2em;
}

.mr-10p {
    margin-right: 10px;
}

.pr-1 {
    padding-right: 1em;
}

.pl-1 {
    padding-left: 1em;
}

.break-word {
    word-break: break-word;
}

.disableIt {
    color: rgba(0, 0, 0, 0.26);
    cursor: not-allowed;
}

.disableItImage {
    opacity: 0.3;
    cursor: not-allowed !important;
}

app-klickdata {
    app-home {
        // All user sections tables have white tools bar
        app-table-toolbar {
            background-color: #fff !important;
        }

        app-table-toolbar mat-toolbar {
            background-color: #fff !important;
        }
    }

    mat-list.mat-list {
        // avoid scroll in course list
        padding-bottom: 2em;
    }

    mat-tab label {
        text-transform: uppercase;
    }

    mat-icon.header-icon {
        height: 48px;
        width: 48px;
        line-height: 48px;
        font-size: 48px;
        color: #666;

        &:not([class^='kd-icon-'], [class*=' kd-icon-']) {
            font-size: 48px;
        }
    }
    @media only screen and (max-width: 959px) {
        mat-toolbar {
            min-height: 50px;

            &.mat-toolbar-single-row {
                height: 50px;
            }

            mat-toolbar-row,
            .mat-toolbar-row {
                height: 50px;
            }

            // app-table-search.align-space {
            //     margin: 0 16px;
            // }
        }
    }
    @media only screen and (min-width: 959px) {
        mat-toolbar,
        .mat-tab-group,
        .mat-tab-nav-bar {
            height: unset !important;
            min-height: unset !important;
            background: none;

            &.mat-toolbar-single-row {
                height: unset !important;
                background: none;
            }
            .mat-tab-labels,
            .mat-tab-links {
                gap: 5px !important;
            }
            a,
            .mat-tab-label {
                padding: 0 12px !important;
                min-width: unset !important;
                height: 38px !important;
                width: auto !important;
            }

            mat-toolbar-row,
            .mat-toolbar-row {
                height: unset !important;
            }

            // app-table-search.align-space {
            //     margin: 0 16px;
            // }
        }
        .unified-tabs {
            a {
                padding: 0 12px;
                background: rgba(0, 0, 0, 0.08);
                min-width: unset;
                height: 38px;
            }
        }
    }
    mat-toolbar.header {
        min-height: 70px !important;

        mat-toolbar-row,
        .mat-toolbar-row {
            height: 64px;
        }

        app-search {
            margin-right: 0.5em;
        }

        app-post .postIcon.mat-icon {
            font-size: 28px;
            width: 28px;
        }

        app-language-selector .mat-icon-button {
            line-height: 0;
            font-size: 18px;
        }
    }

    .mat-chip:not(.mat-basic-chip) {
        margin-top: 2px;
        margin-right: 8px;
        margin-bottom: 2px;
    }

    .mat-chip:not(.mat-basic-chip) + .mat-chip:not(.mat-basic-chip) {
        margin-left: 0;
        margin-top: 2px;
        margin-right: 8px;
        margin-bottom: 2px;
    }

    .mat-chip:not(.mat-basic-chip):focus {
        box-shadow: none;
    }

    mat-chip-list .clear-chip-selection {
        width: 20px;
        height: 20px;
        line-height: 20px;
        background-color: #e0e0e0;
    }
    mat-bottom-sheet-container {
        padding: 0 !important;
    }
}

.cdk-overlay-container .mat-menu-panel .mat-menu-item {
    font-family: Source Sans Pro, sans-serif !important;
}

nav.app-tab-nav-bar {
    max-width: 100%;
    overflow: auto;
}

.app-sidenav-container mat-sidenav {
    background-color: mat.get-color-from-palette($nk3-primary, 50);
}

.app-sidenav-container {
    background-color: transparent;
    width: 100%;
}

.sidebar-menu-item.active {
    background-color: rgba(0, 0, 0, 0.1);
}

.app-sidenav-container > .mat-sidenav-content {
    background: none;
}

.gu-mirror {
    background-color: #49474a;
}

button .mat-spinner {
    width: 3em;
    height: 3em;
}

.note {
    position: relative;
    background-color: whitesmoke;
    padding: 1em 2em;

    &:before {
        border-radius: 0 50% 50% 0;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        left: -4px;
        line-height: 20px;
        position: absolute;
        width: 20px;
        height: 20px;
        text-align: center;
        top: 14px;
        top: calc(50% - 10px);
    }
}

@mixin app-layout-theme($theme) {
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);

    .note {
        border-left: 4px solid mat.get-color-from-palette($accent);

        &:before {
            content: 'i';
            background-color: mat.get-color-from-palette($accent);
        }
    }

    .note.warn {
        border-left: 4px solid mat.get-color-from-palette($warn);

        &:before {
            content: '!';
            background-color: mat.get-color-from-palette($warn);
        }
    }

    .note.primary {
        border-left: 4px solid mat.get-color-from-palette($primary);

        &:before {
            content: 'i';
            background-color: mat.get-color-from-palette($primary);
        }
    }

    div[dir='rtl'].parent-root {
        .note {
            border-right: 4px solid mat.get-color-from-palette($accent);
            border-left: 0;

            &:before {
                content: 'i';
                background-color: mat.get-color-from-palette($accent);
            }
        }

        .note.warn {
            border-right: 4px solid mat.get-color-from-palette($warn);
            border-left: 0;

            &:before {
                content: '!';
                background-color: mat.get-color-from-palette($warn);
            }
        }

        .note.primary {
            border-right: 4px solid mat.get-color-from-palette($primary);
            border-left: 0;

            &:before {
                content: 'i';
                background-color: mat.get-color-from-palette($primary);
            }
        }
    }
}

@media only screen and (max-width: 959px) {
    .p-1-m {
        padding: 1em !important;
    }

    .break-word-m {
        word-break: break-word;
    }

    .mb-0-m {
        margin-bottom: 0 !important;
    }

    .pr-0-m {
        padding-right: 0 !important;
    }
}
